import React from 'react'
import Layout from '../layouts/simple.js'
import '../styles/faq.scss'
import CTA from '../components/cta.js'

const FAQ = () => {
    return (
        <Layout>
            <section className="hero">
                <div className="hero-background">
                    <div className="container">
                        <div className="hero-content">
                            <h1>Frequently Asked Questions</h1>
                            <h3>For 100 years we've made problem-solving designs that are as beautiful as they are useful.</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section id="questions-one" className="py-60 questions">
                <div className="container">
                    <div className="questions-header">
                        <h2>Product care</h2>
                    </div>
                    <div className="column-two">
                        <div>
                            <h4>Herman Miller</h4>
                            <p>Specializes in the design and manufacturing of modular wood casegoods and architectural furniture for private offices and commercial interiors. It is headquartered in Atlanta, Georgia.</p>
                        </div>
                    </div>
                </div>
            </section>
            <CTA />
        </Layout>
    )
    }

    export default FAQ